import Head from 'next/head';
import type { GetStaticProps, NextPage } from 'next';
import { builder, Builder } from '@builder.io/react/lite';

// import components for builder
import '../components/builder';
import BuilderHOC from '@builder/BuilderHOC';
import config from '@helpers/config';
import { useTranslation, withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { TranslationFunction } from '../translation-types';

import HeadingBlock from '@builder/HeadingBlock/HeadingBlock';
interface pageProps {
  content: any;
  t: TranslationFunction<'common'>;
}

type NextPageWithLayout = NextPage<pageProps> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

const FourOFour: NextPageWithLayout = props => {
  const { t } = useTranslation('common');

  const headingBlockContent = {
    header: t('404 Not Found'),
    subheader: t(
      'Oops… It looks like the page you’re looking for can’t be found.'
    ),
    image: {
      src: '/images/contact/pistachios_shell-noshell.png',
      altText: '',
    },
  };

  return (
    <>
      <Head>
        <title>{t('EMEA Pistachios')}</title>
      </Head>
      {!Builder.isEditing && !Builder.isPreviewing && (
        <div className="container" style={{ marginBottom: '60px' }}>
          <div className="row">
            <div className="col-12">
              <HeadingBlock {...headingBlockContent} />
            </div>
          </div>
        </div>
      )}

      {(Builder.isEditing || Builder.isPreviewing) && (
        <BuilderHOC {...props} model={builder.editingModel} />
      )}
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({
  defaultLocale,
  locale,
}) => {
  // reference page model with local
  const builderLocalizedPageModel =
    defaultLocale === locale ? 'page' : `page-${locale}`;

  const content: any = { builderLocalizedPageModel: builderLocalizedPageModel };

  return {
    props: {
      content,
      ...(await serverSideTranslations(locale ? locale : 'en-us', ['common'])),
    },
    revalidate: config.cacheTTL,
  };
};

export default withTranslation('common')(FourOFour);
